import { Component, OnInit, Inject, isDevMode, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/services/api-service.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

declare var OpenSeadragon: any;
@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {
  @ViewChild("defaultStainContainter",{static:false}) defaultStainContainter;

  viewer: any;
  isFullScreen = false;
  imageGroup: StainInterface[] = [];
  selectedStainType: any;
  selectedZoom: number=1;

  constructor(@Inject(DOCUMENT) private document: Document,
              private httpClient: HttpClient,
              private httpService: ApiService,
              private activateRoute: ActivatedRoute) {
  }


  validateUser(sesssionToken, groupId) {
    this.httpService.tokenVerification(sesssionToken).subscribe((response: any) => {
      this.loadImageDetails(sesssionToken, groupId);

    }, (tokenValidateError) => {
      alert('Error occured Authentication failed');
    });
  }


  loadImageDetails(sesssionToken, groupId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        Authorization: 'Bearer ' + sesssionToken
      })
    };

    this.httpClient.get(`${environment.backend}image/groupId/${groupId}`, httpOptions).subscribe((response: any) => {
      this.imageGroup = response.data;
      
      // this.viewer = this.getOsdViewer(this.imageGroup[0].filePath, this.imageGroup[0].dziPath);
      console.log("response.data;")
      if(this.imageGroup.length == 1){
        this.stainChange(this.imageGroup[0].stainType);
      }else{
        this.stainChange('HE');
      }
      

    }, (error: any) => {
      alert('Error occured while loading image details');
    });
  }


  stainingButtonsContainerStyle() {
    return {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '40px',
      'z-index': '5',
      'background-color': '#0085D5'
    };
  }


  getOsdViewer(filePath, dziPath) {
    const viewer = OpenSeadragon({
      id: 'agilent-stanning-viewer',
      prefixUrl: filePath,
      tileSources: dziPath,
      toolbar: 'stainingButtonsContainer',
      showNavigator: true,
      navigatorPosition: 'BOTTOM_RIGHT',
      showZoomControl: false,
      showHomeControl: false,
      showFullPageControl: false,
      minZoomLevel: 0.9,
      defaultZoomLevel: 0.9,
      autoHideControls: false,
    });


    return viewer;
  }
  httpClientSuscription: Subscription | undefined;
  viewerEventsSubscription: Subscription | undefined
  ngOnDestroy() {
    this.sliderValue = 1;
    this.defaultZoomLevel = 0.9;
    this.panCordinates = null;
   
    if (this.viewer) {
      this.httpClientSuscription.unsubscribe();
      this.viewer.close();
      if (this.viewerEventsSubscription) {
        this.viewerEventsSubscription.unsubscribe();
      }
      
      this.viewer.close();
      this.viewer.destroy();
    }
      
  }


  hasStainType(stainType){
    const imageObject = this.imageGroup.find((stain: StainInterface) => stain.stainType === stainType);
    if(imageObject){
      return true;
    }
    return false;
  }
e
  stainChange(stainType) {
    this.selectedStainType = stainType;
    const imageObject = this.imageGroup.find((stain: StainInterface) => stain.stainType === stainType);
    if (this.viewer) {
      this.viewer.destroy();
    }

    if (imageObject) {
      this.viewer = this.getOsdViewer(imageObject.filePath, imageObject.dziPath);

      this.viewer.addHandler('open', () => {

        const displayregion: any = this.document.querySelector('.displayregion');
        displayregion.style.borderColor = 'yellow';
        
        this.canvasZoomHandler(this.viewer);
          setTimeout(()=>{      
       
            if(this.selectedZoom){
              this.viewer.viewport.zoomTo(this.selectedZoom , null, false);
          
            }
            if (this.panCordinates) {
              this.viewer.viewport.panTo(this.panCordinates)
            };
            this.viewer.addHandler('pan', (event: any) => {
              this.panCordinates = event.center;
            });
           

      
      
      },200);
    })

    } else {
      alert('Stain Not Available');
    }
  }
  maxZoom: any;
  sliderValue = 1;
  minZoom: any;
  zoomFactor: number | undefined;



  ngOnInit() {
    const s = this.activateRoute.paramMap.subscribe((params: ParamMap) => {
      const groupId = params.get('g');
      if (!groupId) {
        alert('valid group id expected');
      }
      const token = sessionStorage.getItem('agilent-auth-token');
      const user = JSON.parse(sessionStorage.getItem('userObject'));
      this.loadImageDetails(user.id_token, groupId);
    });



  }
  canvasZoomHandler(viewer: any) {
    console.log("data",viewer)
    viewer.addHandler('zoom', (event: any) => {
      console.log("zoom",event)
      if(event.zoom){
        this.selectedZoom = event.zoom
      }
     
      console.log("selectedZoom",this.selectedZoom)
      if (event.zoom === this.minZoom) {
        this.sliderValue = 1;
      } else {
        this.sliderValue = 1;
        this.maxZoom = this.viewer.viewport.getMaxZoom();
        this.minZoom = this.viewer.viewport.getMinZoom();
        this.zoomFactor = (this.maxZoom - this.minZoom) / 40;
        this.sliderValue = (event.zoom - this.minZoom) / this.zoomFactor;
      }
    });
  }
  canvasPanHandler(viewer: any) {
    this.viewer.addHandler('pan', (event: any) => {
      this.panCordinates = event.center;
    });
  }

  buttonStyle(marginRight = '1px') {
    const styleS: any = {
      display: 'inline-block',
      width: '50px',
      height: '30px',
      'background-color': '#00A9E0',
      'padding-top': '10px',
      'margin-right': marginRight,
      'text-align': 'center',
      color: '#FFFFFF',
      'font-size': '14px',
      'font-family': 'Roboto-medium, Arial, Helvetica, sans-serifRoboto-Medium',
      cursor: 'pointer',
      border: '0px',
    };
    return styleS;

  }


  buttonStyleWithPaddding(marginRight = '1px') {
    const styleS: any = {
      display: 'inline-block',
      width: '50px',
      height: '40px',
      'background-color': '#00A9E0',
      'padding-top': '10px',
      'margin-right': marginRight,
      'text-align': 'center',
      color: '#FFFFFF',
      'font-size': '14px',
      'font-family': 'Roboto-medium, Arial, Helvetica, sans-serifRoboto-Medium',
      cursor: 'pointer',
      border: '0px',
      'margin-top': '-10px',

    };
    return styleS;
  }


  setZoomTo(num: number) {
    this.selectedZoom = num;
    console.log("data",num)
    if (num === 1) {
      console.log("data",num)
      this.viewer.viewport.goHome(false);
    } else {
      console.log("data",num)
      this.viewer.viewport.zoomTo(Number(num));
    }
  }


  goHome() {
    this.viewer.viewport.goHome(true);
    this.sliderZoom(0)
  }


  zoomOut() {
    const gz = this.viewer.viewport.getZoom();
    if ((gz - 1) > 0) {
      this.viewer.viewport.zoomTo(Number(gz) - 1);
    }
  }
    pan(){
    this.viewer.viewport.zoomTo(this.defaultZoomLevel, null, false);
    this.viewer.viewport.applyConstraints();
    this.maxZoom = this.viewer.viewport.getMaxZoom();
    this.minZoom = this.viewer.viewport.getMinZoom();
    this.zoomFactor = (this.maxZoom - this.minZoom) / 40;
    this.sliderValue = (this.defaultZoomLevel - this.minZoom) / this.zoomFactor;
    if (this.panCordinates) {
    this.viewer.viewport.panTo(this.panCordinates)
    };
    }

  zoomIn() {
    const gz = this.viewer.viewport.getZoom();
    this.viewer.viewport.zoomTo(Number(gz) + 1);
  }


  setFullScreen() {
    const displayregion: any = this.document.querySelector('.displayregion');
    displayregion.style.borderColor = 'yellow';

    this.isFullScreen = (this.isFullScreen) ? false : true;

    if (this.isFullScreen) {
      this.viewer.setFullScreen(true);

      const stainingButtonsContainer = this.document.getElementById('stainingButtonsContainer');
      stainingButtonsContainer.style.position = 'absolute';

    } else {
      this.viewer.setFullScreen(false);
    }
  }

  containerSize() {
    return { height: '400px', width: '750px' };
  }
  defaultZoomLevel = 0.9;

  panCordinates: {
    x: number,
    y: number
  };
  sliderZoom(event: any) {
    console.log("sliderZoom",event)
   
    if (event == 0) {
      this.defaultZoomLevel = event;
      this.panCordinates = null;
    }
    this.selectedZoom = event
    this.sliderValue = event
    this.maxZoom = this.viewer.viewport.getMaxZoom();
    this.minZoom = this.viewer.viewport.getMinZoom();
    this.zoomFactor = (this.maxZoom - this.minZoom) / 40;
    this.zoomImage(this.sliderValue * this.zoomFactor + this.minZoom);
    // this.stainTypeZoom = this.sliderValue * this.zoomFactor + this.minZoom;
    this.viewer.panHorizontal = true;
    this.viewer.panVertical = true;
    this.viewer.autoHideControls = true;

  }
  zoomImage(number: number): void { 

    const point = number;
    this.viewer.viewport.zoomTo(point, null, false);
    this.viewer.viewport.applyConstraints();
  }
}
