import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewerComponent } from './stanning-viewer/viewer/viewer.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { AuthGuard } from './services/auth.guard';
import { LoginAuthGuard } from './services/login.gauard';


const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'viewer/:g',
    component: ViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user-portal/user-portal.module').then(m => m.UserPortalModule),
  }, 
  {
    path: 'external',
    loadChildren: () => import('./external-pages/external-pages.module').then(m => m.ExternalPagesModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
