import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule, MatMenuModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentModule } from '../component/components.module';
import { AccountActivationComponent } from './account-activation/account-activation.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoginCallbackComponent } from './login-callback/login-callback.component';

@NgModule({
  declarations: [LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccountActivationComponent,
    LoginCallbackComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthenticationRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatMenuModule,
    NgbDropdownModule,
    ComponentModule,
    NgxCaptchaModule,
    SharedModule,
    TranslateModule,

  ],
  providers:[TranslateService]
})
export class AuthenticationModule { }
